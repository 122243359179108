import React from 'react'
import ImageMain from '../images/mainlook2.png'
import ReactWhatsapp from 'react-whatsapp'

const Header = () => {
  return (
    <header className="main_header">
      <div className='container main_header-container'>
        <div className='main_header-left'>
          <h4>#MeetYourFlexBody</h4>
          <h2>Meet your massage therapist MusleEase</h2>
          <p>MusleEase can provide high-level performance.</p>
          <h1>Price $150</h1>
          <ReactWhatsapp number="+17787926473" className='btn lg' message="Hey, I wanna Buy MusleEase!!" >Buy Now</ReactWhatsapp>
          <div className="offer">
          <h4>Exclusive Offer for our First 101 Customers!</h4>
          <p>Get a $51 Discount using the code ME101 and purchase for only $99. Plus, enjoy free shipping to all locations in Canada.</p>
          </div>
        </div>
        <div className='main_header-right'>
          <div className="main_header_circle"></div>
          <div className="main_header_image">
            <img src={ImageMain} alt="HeaderImage" />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header