import './MusleEase.css'
import Header from '../../component/Header'
import Sectionheader from '../../component/Sectionheader'
import BatterySection from '../../component/BatterySection'
import Footer from '../footer/Footer'

function Muscleease() {
  return (
    <>
    <Header/>
    <Sectionheader/>
    <BatterySection/>
    </>
  )
}

export default Muscleease