import {MdEmail} from 'react-icons/md'
import {IoLogoWhatsapp} from 'react-icons/io'
import {FiPhoneCall} from 'react-icons/fi'

import './contact.css'

export const contact = () => (
  <header title='Contact Us'>
    <div className="contactus_container">
      <div className="circle"></div>
      <div className="textus">
        <h1>Contact Us</h1>
        <h3>Address - Unit 2- 315-252 Gorge Road E, Victoria</h3>
        <h3>Postal code: V9A 6W3</h3>
        <h3>Near By:1907- 13655 Fraser Highway Surrey V3T 0P8</h3>
        <h3>Contact - +1 7787926473</h3>
      </div>
    </div>
    <div className="contact">
      <div className='container contactus_container'>
        <div className="contact_wrapper">
          <a href="mailto:infomuscleease@gmail.com" target="_blank" ><MdEmail /></a>
          <a href="http://wa.me/+17787926473" target="_blank" ><IoLogoWhatsapp /></a>
        </div>
      </div>
    </div>
  </header>
)
export default contact