// import { Link } from 'react-router-dom'
import React from "react"
import './footer.css'
import {FaWhatsapp} from 'react-icons/fa';
import {SiGmail} from 'react-icons/si'
import logo from '../../images/namewebsite4.png'

const Footer = () => {
  return (
   
    <footer>
      <div className="img_logo">
        <img src={logo} alt="logo"/>
        <div className="footer_copyright">
        <small>&copy; 2023 MusleEase. All rights reserved.</small>
        <h5>Address - Gorge Road E, Victoria </h5>
        <h5>Postal code: V9A 6W3</h5>
        <h5>Near By:1907- 13655 Fraser Highway Surrey V3T 0P8</h5>
        <h5>Contact - +1 7787926473</h5>
        </div>
      </div>
      {/* <div className="soical_media">
          <a href="@" ><FaWhatsapp/></a>
          <a href="@"><SiGmail/></a>
        </div> */}
        <ul className='permalinks'>
            <li><a href='/'>MusleEase</a></li>
            <li><a href='/RoyalTrimmer'>Royal Trimmer</a></li>
            <li><a href='/contact'>Contact Us</a></li>   
            <li><a href='http://wa.me/+17787926473'>Support</a></li>   
        </ul>

        
      
    </footer>  
   )
}

export default Footer