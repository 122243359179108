import React from 'react'
import sectionheader_image from '../images/sp.jpg'
import standardball from '../images/ball.png'
import fingertrip from '../images/fingertrip.png'
import thump from '../images/thump.png'
import fork from '../images/fork.png'
import damper from '../images/damper.png'
import wedge from '../images/wedge3.png'

function Sectionheader() {
  return (
    // <header title='Specification' image = {sectionheader_image}>
    // </header>
    <>
          {/* // <header title='Specification' image = {sectionheader_image}>
          // </header> */}
          <header className="main_sectionheader">
              <div className='main_sectionheader_container'>
                  <div className='main_sectionheader_title'>
                      <h3>Attachments And Specification</h3>
                  </div>
                  <div className="main_sectionheader_image">
                      <img src={sectionheader_image} alt="HeaderImage" />
                  </div>
              </div>

              {/* /kamdfk;madlk;fmakdmf */}
          <section className='attachments'>
              <div className="container_sectionball">
                <div className="sectionball_circle"></div>
                  <div className="sectionball_image">
                        <img src={standardball} alt="Standard Ball" />
                    </div>
                            <div className="sectionball_header">
                                <div className="sectionball_contect">
                                    <h3>Standard Ball</h3>
                                    <h5>Perfect for use on large & small muscle groups.</h5>    
                                </div>
                            </div>
                    
              </div>
              {/* second */}
              <div className="container_sectionfingertrip">
                <div className="sectionfingertrip_circle"></div>
                  <div className="sectionfingertrip_image">
                        <img src={fingertrip} alt="fingertrip" />
                            </div>
                            <div className="sectionfingertrip_header">
                                <div className="sectionfingertrip_contect">
                                <h3>Fingertrip</h3>
                        <h5>For trigger points and the lower back.</h5>    
                    </div>
                    </div>
                </div>

                {/* third */}
                <div className="container_sectionthump">
                    <div className="sectionthump_circle"></div>
                        <div className="sectionthump_image">
                        <img src={thump} alt="thump" />
                        </div>
                            <div className="sectionthump_header">
                                <div className="sectionthump_contect">
                                <h3>Thumb</h3>
                        <h5>Pinpoint muscle treatment, trigger points, and small muscle areas like the hands and feet.</h5>    
                    </div>
                    </div>
                </div>
          {/* </section> */}

          {/* 2222222222222222222222222222222222222222222222222 */}
          {/* <section className='attachments2'> */}
              <div className="container_sectionfork">
                <div className="sectionfork_circle"></div>
                  <div className="sectionfork_image">
                        <img src={fork} alt="fork" />
                    </div>
                            <div className="sectionfork_header">
                                <div className="sectionfork_contect">
                                    <h3>Fork</h3>
                                    <h5>Glides perfectly along your  trapezoid muscles starting from the upper neck area to your shoulders.</h5>    
                                </div>
                            </div>
                    
              </div>
              {/* second */}
              <div className="container_sectionwedge">
                <div className="sectionwedge_circle"></div>
                  <div className="sectionwedge_image">
                        <img src={wedge} alt="wedge" />
                            </div>
                            <div className="sectionwedge_header">
                                <div className="sectionwedge_contect">
                                <h3>Wedge</h3>
                        <h5>For trigger points and the lower back.</h5>    
                    </div>
                    </div>
                </div>

                {/* third */}
                <div className="container_sectiondamper">
                    <div className="sectiondamper_circle"></div>
                        <div className="sectiondamper_image">
                        <img src={damper} alt="damper" />
                        </div>
                            <div className="sectiondamper_header">
                                <div className="sectiondamper_contect">
                                <h3>Damper</h3>
                        <h5>Pinpoint muscle treatment, trigger points, and small muscle areas like the hands and feet.</h5>    
                    </div>
                    </div>
                </div>
          </section>
          </header>
          </>
    )
}

export default Sectionheader