import './about.css'
import { Link } from 'react-router-dom'
import ghostpirate from '../../images/ghostpirate.png'
import scisorghost from '../../images/sciscorghost.png'
import golden from '../../images/goldentrimmer.png'
import rainbow from '../../images/rainbowcolour.png'
import ghosthead from '../../images/ghosthead.png'
import ultimateghost from '../../images/ultimatehead.png'
import tiger from '../../images/tiger.png'
import whitedragon from '../../images/whitedrAGON.png'
import buddha from '../../images/buddha.png'
import ReactWhatsapp from 'react-whatsapp'


export const About = () => {
  return (
  <header className='mainheader'>
    <div className="trimmer">
      
      
    <div className="ghostpiratecircle"></div>
    <div className="ghostpiratehead">
      <img src={ghostpirate} alt='Ghostpirateimg'/>
    <div className="ghostpirate_contect">
      <h3>Ghost Pirate Edition $50</h3>
      <h5></h5>
    </div>
      <div className="buyghostpirate">
      <ReactWhatsapp number="+17787926473" className='btn ghostpirate' message="Hey, I wanna Buy Ghost Pirate Edition!!">Buy Now</ReactWhatsapp>
      {/* <Link to="/" className='btn ghostpirate'>Buy Now</Link> */}
    </div>
    </div>



    <div className="scisorghostcircle"></div>
    <div className="scisorghosthead">
      <img src={scisorghost} alt='ScisorGhostimg'/>
    <div className="scisorghost_contect">
      <h3>Scissor Ghost Edition $50</h3>
      <h5></h5>
    </div>
      <div className="buyscisorghost">
      <ReactWhatsapp number="+17787926473" className='btn scisorghost' message="Hey, I wanna Buy Scissor Ghost Edition!!">Buy Now</ReactWhatsapp>
      {/* <Link to="/" className='btn scisorghost'>Buy Now</Link> */}
    </div>
    </div>
    

    <div className="goldenheadcircle"></div>
    <div className="goldenhead">
      <img src={golden} alt="goldenimg"/>
    <div className="golden_contect">
      <h3>Golden Edition $45</h3>
      <h5></h5>
    </div>
      <div className="buygolden">
      <ReactWhatsapp number="+17787926473" className='btn golden' message="Hey, I wanna Buy Golden Edition!!">Buy Now</ReactWhatsapp>
      {/* <Link to="/" className='btn golden'>Buy Now</Link> */}
    </div>
    </div>
    
    <div className="rainbowcircle"></div>
    <div className="rainbowhead">
      <img src={rainbow} alt='rainbowimg'/>
    <div className="rainbow_contect">
      <h3>Neon Blue Edition $45</h3>
      <h5></h5>
    </div>
      <div className="buyrainbow">
      <ReactWhatsapp number="+17787926473" className='btn rainbow' message="Hey, I wanna Buy Neon Blue Edition!!">Buy Now</ReactWhatsapp>
      {/* <Link to="/" className='btn rainbow'>Buy Now</Link> */}
    </div>
    </div>
   
    <div className="ghostheadcircle"></div>
    <div className="ghostheadhead">
      <img src={ghosthead} alt="ghostheadimg" />
    <div className="ghosthead_contect">
      <h3>Rose Ghost Edition $45</h3>
      <h5></h5>
    </div>
      <div className="buyghosthead">
      <ReactWhatsapp number="+17787926473" className='btn ghosthead' message="Hey, I wanna Buy Rose Ghost Edition!!">Buy Now</ReactWhatsapp>
      {/* <Link to="/" className='btn ghosthead'>Buy Now</Link> */}
    </div>
    </div>
    
    <div className="ultimateghostcircle"></div>
    <div className="ultimateghosthead">
    <img src={ultimateghost} alt='ultimateghostimg' />
    <div className="ultimateghost_contect">
      <h3>Ultimate Rider Edition $45</h3>
      <h5></h5>
    </div>
      <div className="buyultimateghost">
      <ReactWhatsapp number="+17787926473" className='btn ultimateghost' message="Hey, I wanna Buy Ultimate Rider Edition!!">Buy Now</ReactWhatsapp>
      {/* <Link to="/" className='btn ultimateghost'>Buy Now</Link> */}
    </div>
    </div>
    
    <div className="tigerheadcircle"></div>
    <div className="tigerhead">
      <img src={tiger} alt="tigerimg"/>
      <div className="tiger_contect">
      <h3>Bengal Tiger Edition $45</h3>
      <h5></h5>
    </div>
      <div className="buytiger">
      <ReactWhatsapp number="+17787926473" className='btn tiger' message="Hey, I wanna Buy Bengal Tiger Edition!!">Buy Now</ReactWhatsapp>
      {/* <Link to="/" className='btn tiger'>Buy Now</Link> */}
    </div>
    </div>
    
    <div className="whitedragoncircle"></div>
    <div className="whitedragonhead">
      <img src={whitedragon} alt='whitedragomimg' />
      <div className="whitedragon_contect">
      <h3>Golden Dragon Edition $30</h3>
      <h5></h5>
    </div>
      <div className="buywhitedragon">
      <ReactWhatsapp number="+17787926473" className='btn whitedragon' message="Hey, I wanna Buy Golden Dragon Edition!!">Buy Now</ReactWhatsapp>
      {/* <Link to="/" className='btn whitedragon'>Buy Now</Link> */}
    </div>
    </div>
    
    <div className="buddhaheadcircle"></div>
    <div className="buddhahead">
      <img src={buddha} alt='buddhaimg' />
      <div className="buddha_contect">
      <h3>The Buddha Edition $30</h3>
      <h5></h5>
    </div>
      <div className="buybuddha">
      <ReactWhatsapp number="+17787926473" className='btn buddha' message="Hey, I wanna Buy The Buddha Edition!!">Buy Now</ReactWhatsapp>
      {/* <Link to="/" className='btn buddha'>Buy Now</Link> */}
    </div>
    </div>
  </div>
  </header>
  )
}
export default About
