import { BrowserRouter, Routes, Route } from 'react-router-dom'

import MusleEase from './pages/muscleease/MusleEase'
import RoyalTrimmer from './pages/about/About'
import Contact from './pages/contact/Contact'
import NotFound from './pages/notFound/NotFound'
import NavBar from './component/NavBar'
import Footer from './pages/footer/Footer'

const App = () => {
  return (
    <BrowserRouter> 
      <NavBar/>
      <Routes>
        <Route index element = {<MusleEase/>}/>
        <Route path='RoyalTrimmer' element={<RoyalTrimmer/>}/>
        <Route path='contact' element={<Contact/>}/>
        <Route path='*' element={<NotFound/>}/>

      </Routes>
      <Footer/>
    </BrowserRouter>
  )
}
export default App