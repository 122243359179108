import React from 'react'
import batterydetails from '../images/Untitled-2.jpg'
import frequencydetails from '../images/FRE.jpg'

const BatterySection = () => {
  return (
    <header className='battery_header'>
        <div className='batteryheader_container'>
        <div className="batteryheader_title">
            
        </div>
        <div className="batteryheader_image">
            <img src={batterydetails} alt="battery" />
        </div>
        </div>
        <div className='frequencyheader_container'>
        <div className="frequencyheader_title">
            
        </div>
        <div className="frequencyheader_image">
            <img src={frequencydetails} alt="frequency" />
        </div>
        </div>
    </header>
  )
}

export default BatterySection